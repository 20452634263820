<template>
  <v-app>
    <div>
      <div class="card card-custom">
        <div class="card-body p-0">
          <div
              class="wizard wizard-2"
              id="kt_wizard_v2"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
          >
            <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
              <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-12">
                  <h3>
                    Attendance report
                  </h3>
                  <router-link :to="{name:'dashboard'}">
                    Dashboard
                  </router-link>
                  \
                  Attendance
                </div>
              </div>
              <div class="row" v-if="checkIsAccessible('attendance-report', 'list')">
                <form @submit.prevent="validateSearch('report')" class="row mt-5 mb-5 bg-filter">
                  <div class="col-3">
                    <v-select outlined dense :items="search_types" v-model="search.type" item-value="value"
                              item-text="title" label="Type"
                              @change="attendance = []"></v-select>
                    <span class="text-danger mt-2"
                          v-if="$v.search.type.$error">This information is required</span>
                  </div>

                  <div class="col-3" v-if="search.type == 'by_date'">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined dense
                                      v-model="search.attendance_date"
                                      label="Date"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.attendance_date"
                          @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <span class="text-danger mt-2"
                          v-if="search.type != 'current_month' && $v.search.attendance_date.$error">This information is required</span>
                  </div>
                  <div class="col-3" v-if="search.type == 'by_date_range'">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined dense
                                      v-model="dates"
                                      label="Choose start and end date"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          range
                          v-model="dates"
                          @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                    <span class="text-danger mt-2"
                          v-if="search.type != 'current_month' && $v.search.attendance_date.$error">This information is required</span>
                  </div>
                  <div class="col-md-3">
                    <v-select
                        class="form-control"
                        outlined
                        v-model="search.academic_year_id"
                        dense
                        :items="academicYears"
                        item-text="title"
                        item-value="id"
                        label="Academic year"
                    >
                    </v-select>
                    <span class="text-danger mt-2"
                          v-if="$v.search.academic_year_id.$error">This information is required</span>
                  </div>
                  <div class="col-md-3">
                    <v-select
                        class="form-control"
                        outlined
                        v-model="search.level_id"
                        dense
                        :items="levels"
                        item-text="title"
                        item-value="id"
                        label="Level"
                        @change="getPrograms(search.level_id)"
                    >
                    </v-select>
                    <span class="text-danger mt-2"
                          v-if="$v.search.level_id.$error">This information is required</span>
                  </div>
                  <div class="col-md-3">
                    <v-select
                        class="form-control"
                        outlined
                        :items="programs"
                        v-model="search.program_id"
                        @change="getGrades(search.program_id)"
                        dense
                        item-text="title"
                        item-value="id"
                        label="Program"
                    >
                    </v-select>
                    <span class="text-danger mt-2"
                          v-if="$v.search.program_id.$error">This information is required</span>
                  </div>
                  <div class="col-md-3">
                    <v-select
                        class="form-control"
                        outlined
                        :items="gradesLevels"
                        v-model="search.grade_id"
                        dense
                        item-text="title"
                        item-value="id"
                        label="Semester/year"
                        @change="getAcademicClasses(search.academic_year_id, search.program_id, search.grade_id)"
                    >
                    </v-select>
                    <span class="text-danger mt-2"
                          v-if="$v.search.grade_id.$error">This information is required</span>
                  </div>
                  <div class="col-md-3">
                    <v-select
                        class="form-control"
                        outlined
                        :items="academic_classes"
                        v-model="search.academic_class_id"
                        dense
                        item-text="title"
                        item-value="id"
                        label="Class"
                        @change="callMethods(search.academic_class_id)"
                    >
                    </v-select>
                    <span class="text-danger mt-2"
                          v-if="$v.search.academic_class_id.$error">Class is required</span>
                  </div>
                  <!--                  <div class="col-md-3">-->
                  <!--                    <v-select-->
                  <!--                        class="form-control"-->
                  <!--                        outlined-->
                  <!--                        :items="report_type"-->
                  <!--                        v-model="search.report_type"-->
                  <!--                        dense-->
                  <!--                        item-text="title"-->
                  <!--                        item-value="value"-->
                  <!--                        label="Report Type"-->
                  <!--                    >-->
                  <!--                    </v-select>-->
                  <!--                    <span class="text-danger mt-2"-->
                  <!--                          v-if="$v.search.report_type.$error">Report type is required</span>-->
                  <!--                  </div>-->
<!--                  <div class="col-md-3" v-if="search.report_type == 'by_subject' && search.type == 'by_date' ">-->
<!--                    <v-select-->
<!--                        class="form-control"-->
<!--                        outlined-->
<!--                        :items="assigned_courses"-->
<!--                        v-model="search.course_id"-->
<!--                        dense-->
<!--                        item-text="title"-->
<!--                        item-value="id"-->
<!--                        label="Subject"-->
<!--                    >-->
<!--                    </v-select>-->
<!--                    <span class="text-danger mt-2"-->
<!--                          v-if="$v.search.course_id.$error">Course is required</span>-->
<!--                  </div>-->
                  <div class="col-md-3" v-if="search.type =='by_date_range'">
                    <v-text-field v-model="search.title" label="Search by student name" outlined dense></v-text-field>
                  </div>
                  <div class="col-3" v-if="search.academic_class_id">
                    <v-select outlined dense :items="teachers" v-model="search.teacher_id" item-value="id"
                              item-text="full_name" label="Teacher"></v-select>
                  </div>
                  <div class="col-4">
                    <v-btn type="submit" depressed class="btn btn-primary text-white" :loading="loading">Search</v-btn>
                    <v-btn depressed class="btn btn-standard ml-2" @click="resetForm">Reset</v-btn>
                    <v-btn depressed class="btn btn-success ml-2" @click="validateSearch('export')">Export</v-btn>
                  </div>
                  <!--                  <pre>{{ search }}</pre>-->
                </form>
                <v-row v-if="search.type == 'by_date' &&  attendance">
                  <table class="table ">
                    <thead>
                    <th class="text-center">Name</th>
                    <th  class="text-center" v-for="(course, index) of attendance.courses" :key="index">
                      {{ course }}
                    </th>
                    </thead>
                    <tbody>
<!--                    <tr v-for="(attendee, index) of attendees" :key="index">-->
<!--                      <td class="text-center">{{ attendee.full_name }}</td>-->
<!--                      <td class="text-center text-white" :style="{backgroundColor: getColor(attendee.attendance)}">-->
<!--                        <span>{{ attendee.attendance.toUpperCase() }}</span>-->
<!--                      </td>-->
<!--                    </tr>-->
                    <tr v-for="(attendee, index) of attendance.attendance" :key="index">
                      <td class="text-center">{{ attendee.full_name }}</td>
                      <td class="text-center text-white text-uppercase font-weight-bold" v-for="(report, i) in attendee.report" :key="i" :style="{backgroundColor: getColor(report)}">
                         {{ report }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-row>
                <v-row v-if="(search.type == 'by_date_range' || search.type == 'current_month') &&  attendance">
                  <!--                <v-row>-->
                  <!--                  <pre>{{attendance}}</pre>-->
                  <table class="table ">
                    <thead>
                    <th class="text-center">Name</th>
                    <th v-for="(course, index) of attendance.courses" :key="index">
                      {{ course }}
                    </th>
                    <th class="text-center"></th>
                    </thead>
                    <tbody>
                    <tr v-for="(attendee, index) of attendance.attendance" :key="index">
                      <td class="text-center">{{ attendee.full_name }}</td>
                      <td v-for="(log, i) in attendee.report" :key="i">
                        Present : {{ log.total_present }} <br>
                        Absent : {{ log.total_absent }}<br>
                        Half : {{ log.total_half_day }}<br>
                        Late : {{ log.total_late }}

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import AttendanceService from "@/core/services/attendance/AttendanceService";
import AcademicClassTeacherService from "@/core/services/academic-class/class-teacher/AcademicClassTeacherService";
import FilterMixin from "@/mixins/FilterMixin";
import {required} from "vuelidate/lib/validators";
import {AttendanceMixin} from "@/mixins/AttendanceMixin";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import {API_URL} from "@/core/config";

const academicClassService = new AcademicClassService();
const attendanceService = new AttendanceService();
const teacherService = new AcademicClassTeacherService();

export default {
  name: "Report",
  mixins: [FilterMixin, AttendanceMixin],
  validations() {
    return {
      search: this.validation_rule
    }
  },
  data() {
    return {
      search: {
        attendance_date: null,
        type: "by_date",
        academic_year_id: null,
        program_id: null,
        grade_id: null,
        report_type: 'summary',
        level_id: null,
        academic_class_id: null,
        course_id: null
      },
      menu2: false,
      loading: false,
      dates: [],
      class_course: [],
      teachers: [],
      attendance: [],
      report_type: [
        {title: 'Summary', value: 'summary'},
        {title: 'By Subject', value: 'by_subject'},
      ],
      search_types: [
        {title: 'By Date', value: 'by_date'},
        {title: 'By Date Range', value: 'by_date_range'},
        {title: 'Current Month', value: 'current_month'},
      ],
      attendees: [],
    }
  },
  computed: {
    today() {
      return this.$moment(new Date).format('YYYY-MM-DD');
    },
    validation_rule() {
      let rule = {
        type: {required},
        academic_year_id: {required},
        program_id: {required},
        level_id: {required},
        grade_id: {required},
        academic_class_id: {required},
        report_type: {required},
      }
      if (this.search.type != 'current_month')
        rule.attendance_date = {required}
      return rule;
    }
  },
  mounted() {
    this.search.attendance_date = this.today;
    this.getAcademicYears()
    this.getLevels()
  },
  methods: {
    getReport() {
      this.loading = true
      attendanceService.getAttendanceReport(this.search).then(response => {
        // if (this.search && this.search.type == 'by_date') {
          this.attendance = response.data.attendance;
        // } else {
        //   this.attendance = response.data.attendance;
        // }
        this.loading = false
      }).catch(error => {
        this.$snotify.error('Error while fetching data')
      }).finally(this.loading = false)
    },
    validateSearch(type) {
      if (this.search && this.search.type == 'by_date_range')
        this.search.attendance_date = this.dates
      this.$v.search.$touch();
      if (this.$v.search.$error) {
        setTimeout(() => {
          this.$v.search.$reset();
        }, 3000)
      } else {
        if (type == 'report')
          this.getReport();
        else
          this.exportAttendance();
      }
    },
    callMethods(classId) {
      this.getAssignedCourses(classId)
      this.getAssignedTeachers(classId)
    },
    getAssignedTeachers(class_id) {
      this.loading = true;
      academicClassService.getAssignedTeachers(class_id).then(response => {
        let teacher = response.data.teachers;
        this.teachers = teacher.map(ele => {
          return {id: ele.teacher.id, full_name: ele.teacher.full_name}
        })
      }).finally(() => {
        this.loading = false;
      });
    },
    resetForm() {
      this.search = {
        attendance_date: null,
        type: "by_date",
        academic_year_id: null,
        program_id: null,
        grade_id: null,
        report_type: null,
        level_id: null,
        academic_class_id: null,
        course_id: null
      }
      this.attendance =[]
          this.attendees =[]
    },
    exportAttendance() {
      var queryString = Object.keys(this.search).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(this.search[key])
      }).join('&');
      window.open(
          API_URL + "download/attendance/ledger?" + queryString,
          "_blank"
      );
    }
  }
}
</script>

<style>
.form-control {
  border: none !important;
}

.attenedance table {
  border-collapse: collapse;
}

.attenedance table, th, td {
  /*border: 1px solid black;*/
}

.table th, .table thead th, .table td {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table {
  background: #fff;
}

.v-input--selection-controls__input + .v-label {
  margin-bottom: 0 !important;
}

.v-toolbar__title {
  padding-left: 10px;
}

.table .v-input .v-messages,
.v-input--radio-group .v-messages {
  display: none;
}

.v-toolbar__content .v-input--radio-group--row {
  margin-left: 10px;
}

.v-toolbar__content .v-input--radio-group--row + .v-input--radio-group--row {
  margin-left: 0px;
}

.v-toolbar__content .v-input--radio-group--row .v-input__slot {
  margin-bottom: 0;
}

.v-input__slot {
  background: transparent !important;

}

.table-bordered, .table-bordered th, .table-bordered td {
  border-color: #000 !important;
}
</style>